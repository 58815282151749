
const endPoint = {
    login:"api/login",
    categories:"api/categories",
    produits:"api/produits",
    promotions:"promotions",
    livreurs:"livreurs",
    fournisseurs:"fournisseurs",
    clients:"clients",
    administrateurs:"administrateurs",
}

export default endPoint